import {captureException} from "@sentry/react";

export async function searchBarcodes(apiToken: string, searchText: string) {
    if (!apiToken) {
        throw new Error('Missing API Token');
    }

    const searchParams = new URLSearchParams({text: searchText});
    try {
        const response = await fetch(`https://api.s-wipe.io/api/admin/searchBarcodes?${searchParams}`, {
            headers: {
                Authorization: `Bearer ${apiToken}`,
                "Content-Type": "application/json"
            },
            method: 'GET',
        });

        const json = await response.json();
        if (json.success) {
            return json.data;
        }
    } catch (error) {
        const msg = `Cannot search barcodes with "${searchText}": ${error}`;
        console.error(msg, {cause: error});
        captureException(msg);
    }

    return [];
}

export async function searchUsers(apiToken: string, searchTest: string) {
    if (!apiToken) {
        throw new Error('Missing API Token');
    }

    const searchParams = new URLSearchParams({text: searchTest});
    try {
        const response = await fetch(`https://api.s-wipe.io/api/admin/searchUsers?${searchParams}`, {
            headers: {
                Authorization: `Bearer ${apiToken}`,
                "Content-Type": "application/json"
            },
            method: 'GET',
        });

        const json = await response.json();
        if (json.success) {
            return json.data;
        }
    } catch (error) {
        const msg = `Cannot search users with "${searchTest}": ${error}`;
        console.error(msg, {cause: error});
        captureException(msg);
    }

    return [];
}

export async function createUser(apiToken: string, firstName: string, lastName: string, email: string, kitId?: string) {
    const data = {
        firstName,
        lastName,
        email,
        kitId,
    };

    const response = await fetch('https://api.s-wipe.io/api/users', {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${apiToken}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    });

    if (!response.ok) {
        throw new Error(`Cannot create user ${firstName} ${lastName}: ${response.statusText}`);
    }

    const json = await response.json();

    if (!json.success) {
        throw new Error(`Cannot create user ${firstName} ${lastName}: ${json.message}`);
    }

    return json.data;
}