import {Stack, ToggleButton} from 'react-bootstrap';
import 'react-multi-carousel/lib/styles.css';
import Button from "react-bootstrap/Button";
import {useUserAuth} from "../../../../contexts/UserAuthContext";
import {useState} from "react";
import StackWithButtons from "../../../../components/StackWithButtons";
import MultiOptionSelector, { MultiOption } from "../../../../components/MultiOptionSelector";
import React from 'react';

const Supplements = (props: {
    prev: () => void;
    next: (selected: Array<string>) => void;
}) => {
    const {updateScanData, getLastTestExcept} = useUserAuth();
    // const previousTest = getLastTestExcept(barcode);
    // const supplements = ["AXOS Supplement", "GOS Supplement", "Inulin Supplement", "Maise/Corn Supplement",
    //     "Polydextrose Supplement", "Wheat Supplement", "Whole Grain Supplement", "XOS Supplement"];
    const supplements = [
        new MultiOption("AXOS Supplement", undefined, undefined, "AXOS is a prebiotic supplement that is derived from arabinoxylan-oligosaccharides, which are found in the cell walls of cereal grains such as wheat and rye."),
        new MultiOption("GOS Supplement", undefined, undefined, "GOS is a prebiotic supplement that is derived from galactooligosaccharides, which are found in dairy products and some root vegetables."),
        new MultiOption("Inulin Supplement", undefined, undefined, "Inulin is a prebiotic supplement that is derived from inulin, which is found in chicory root, garlic, and onions."),
        new MultiOption("Maise/Corn Supplement", undefined, undefined, "Maise/Corn is a prebiotic supplement that is derived from maize/corn."),
        new MultiOption("Polydextrose Supplement", undefined, undefined, "Polydextrose is a prebiotic supplement that is derived from polydextrose, which is a synthetic polymer of glucose."),
        new MultiOption("Wheat Supplement", undefined, undefined, "Wheat is a prebiotic supplement that is derived from wheat."),
        new MultiOption("Whole Grain Supplement", undefined, undefined, "Whole Grain is a prebiotic supplement that is derived from whole grains such as oats, barley, and brown rice."),
        new MultiOption("XOS Supplement", undefined, undefined, "XOS is a prebiotic supplement that is derived from xylooligosaccharides, which are found in bamboo shoots, fruits, and vegetables.")
    ]
    const [selectedSupplements, setSelectedSupplements] = useState<Array<string>>([]);

    return (
        <StackWithButtons title="Fiber Supplements"
                          description="What fiber supplements do you take? Skip if you don't take any fiber supplements"
                          prev={props.prev}
                          next={() => props.next(selectedSupplements)}>
            <MultiOptionSelector options={supplements} selectedOptions={selectedSupplements}
                                 setSelectedOptions={setSelectedSupplements} hasNoneOption/>
        </StackWithButtons>
    )
}

export default Supplements;