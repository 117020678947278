import ReactDOM from 'react-dom/client';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import './assets/styles/index.css';
import './assets/styles/custom.scss';
import Layout from './pages/Layout';
import Home from './pages/account/Home';
import Login from './pages/Login';
import Register from "./pages/Register";
import Landing from "./pages/Landing";
import {UserAuthContextProvider} from "./contexts/UserAuthContext";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import Details from "./pages/account/details/Details";
import Test from "./pages/account/test/TestWizard";
import VerifyEmail from "./pages/VerifyEmail";
import PageNotFound from "./pages/404Page";
import ResetPassword from "./pages/ResetPassword";
import Title from "./components/Title";
import Error from "./pages/Error";
import AdminRoutes from "./routes/AdminRoutes";
import Admin from "./pages/account/admin/Admin";
import TestScan from "./pages/account/admin/TestScan";
import Tests from "./pages/account/Tests";
import Reports from "./pages/account/Reports";
import Report from "./pages/account/Report";
import SignInWithLink from "./pages/actions/SignInWithLink";
import TestKits from "./pages/account/kits/TestKits";
import AddTestKit from "./pages/account/kits/AddTestKit";
import Messages from "./pages/account/Messages";
import UserInformation from './pages/account/details/UserInformation';
import AddUser from "./pages/account/admin/AddUser";


function App() {

    return (
        <BrowserRouter>
            <Sentry.ErrorBoundary fallback={<div>Something went wrong.</div>}>
                <UserAuthContextProvider>
                    <Routes>
                        <Route path="/" element={<Layout/>}>
                            <Route index element={<Landing/>}/>
                            <Route path="login" element={<Title title="Log In"><Login/></Title>}/>
                            <Route path="register" element={<Title title="Sign Up"><Register/></Title>}/>
                            <Route path="verify-email" element={<VerifyEmail/>}/>
                            <Route path="forgotpassword" element={<ResetPassword/>}/>
                            <Route path="error" element={<Error/>}/>
                            <Route path="account" element={<ProtectedRoutes/>}>
                                <Route index element={<Title title="Account"><Home/></Title>}/>
                                <Route path="tests" element={<Title title="Tests"><Tests/></Title>}/>
                                <Route path="kits">
                                    <Route index element={<Title title="Test Kits"><TestKits/></Title>}/>
                                    <Route path="add" element={<Title title="Add Test Kit"><AddTestKit/></Title>}/>
                                </Route>
                                <Route path="reports" element={<Title title="Reports"><Reports/></Title>}/>
                                <Route path="details">
                                    <Route index element={<Title title="Details"><UserInformation/></Title>}/>
                                    <Route path="edit" element={<Title title="Edit Details"><Details/></Title>}/>
                                </Route>
                                <Route path="messages" element={<Title title="Messages"><Messages/></Title>}/>
                                <Route path="test" element={<Title title="Test"><Test/></Title>}/>
                                <Route path="report/:id" element={<Title title="Report"><Report/></Title>}/>
                                <Route path="admin" element={<AdminRoutes/>}>
                                    <Route index element={<Title title="Admin"><Admin/></Title>}/>
                                    <Route path="scan" element={<TestScan/>}/>
                                    <Route path="user/add" element={<AddUser/>}/>
                                </Route>
                            </Route>
                            <Route path="action">
                                <Route path="signinwithlink" element={<Title title="Sign In"><SignInWithLink/></Title>}/>
                            </Route>
                            <Route path="*" element={<PageNotFound/>}/>
                        </Route>
                    </Routes>
                </UserAuthContextProvider>
            </Sentry.ErrorBoundary>
        </BrowserRouter>
    )
}

Sentry.init({
    dsn: "https://612df2002e90bd40af4c64e9ac46d426@o4507510472441856.ingest.us.sentry.io/4507510475325440",
    // integrations: [Sentry.replayIntegration()],
    environment: process.env.REACT_APP_ENVIRONMENT ?? "development",
    // // Session Replay
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>);

// const adjustHeight = () => document.documentElement.style.setProperty(
//     '--app-height', `${window.innerHeight}px`);
// window.addEventListener('resize', adjustHeight);
// adjustHeight();
