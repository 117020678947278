import {useUserAuth} from "../../contexts/UserAuthContext";
import {getAvailableTests, getUsedTests, getUserReports} from "../../firebase";
import {Stack, Table} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import Column from "../../components/Column";
import moment from 'moment';
import {Timestamp} from 'firebase/firestore';
import {getTestStatus, trim} from "../../utils/Utils";
import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import Preview from "../../components/Preview";
import Report from "../../models/Report";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faEye, faPoo, faVials} from "@fortawesome/free-solid-svg-icons";
import Test from "../../models/Test";
import {checkAllUserData} from "../../models/User";

const Home = () => {

    const {userData, logOut, user} = useUserAuth();
    const [userReports, setUserReports] = useState<Array<Report>>([]);
    const [userTests, setUserTests] = useState([]);
    const [numAvailableTests, setNumAvailableTests] = useState<number>(0);
    const navigate = useNavigate();

    useEffect(() => {
        if (!!userData && !checkAllUserData(userData)) {
            navigate("/account/details/edit");
            return;
        }

        getUserReports(user.uid)
            .then((reports: Report[]) => setUserReports(reports));

        getUsedTests(user.uid)
            .then((tests) => setUserTests(tests));

        getAvailableTests(user.uid)
            .then((tests: Test[]) => setNumAvailableTests(tests.length));
    }, [user.uid, userData, navigate]);

    function formatDate(date) {
        if (date === undefined || date === null)
            return null;
        if (date instanceof Timestamp)
            date = date.toDate();
        return moment(date).format('MM/DD/YYYY');
    }

    return (
        <>
            <Column>
                <Stack className="my-3 gap-md-5 my-md-5" gap={3}>

                    <Preview header="Your profile" href="/account/details">
                        <Table className="table-sm table-borderless m-0" hover>
                            <tbody>
                            <tr>
                                <th>Name:</th>
                                <td>{userData?.firstName} {userData?.lastName}</td>
                            </tr>
                            <tr>
                                <th>Email:</th>
                                <td>{userData?.email}</td>
                            </tr>
                            <tr>
                                <th>Diet:</th>
                                <td>{trim(userData?.diets?.join(', '))}</td>
                            </tr>
                            {!!userData && !!userData.gutIssues &&
                                <tr>
                                    <th>Gut issues:</th>
                                    <td>{trim(userData.gutIssues)}</td>
                                </tr>
                            }
                            </tbody>
                        </Table>
                    </Preview>

                    <Preview header="Your tests" href="/account/tests">
                        {!!userTests && userTests.length > 0
                            ? <Table className="table-sm m-0" hover>
                                <tbody>
                                {
                                    userTests.sort((x, y) => y.date - x.date)
                                        .slice(0, 3)
                                        .map((test, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{test.testId}</td>
                                                <td>{formatDate(test.date)}</td>
                                                <td className="small">{getTestStatus(test)}</td>
                                            </tr>
                                        ))
                                }
                                </tbody>
                            </Table>
                            : <p className="text-center">No tests available yet</p>
                        }
                    </Preview>

                    <Preview header="Your reports" href="/account/reports">
                        {!!userReports && userReports.length > 0
                            ? <Table className="table-sm m-0" hover>
                                <tbody>
                                {
                                    userReports.sort((x, y) => y.date.valueOf() - x.date.valueOf())
                                        .slice(0, 3)
                                        .map((report, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{formatDate(report.date)}</td>
                                                <td>
                                                    <a href={report.downloadURL} target="_blank" rel="noreferrer">
                                                        <FontAwesomeIcon icon={faDownload} size="lg" className="me-1"/>
                                                        Download
                                                    </a>
                                                </td>
                                                <td>
                                                    <a href={"/account/report/" + report.reportId}>
                                                        <FontAwesomeIcon icon={faEye} size="lg" className="me-1"/>
                                                        View
                                                    </a>
                                                </td>
                                            </tr>
                                        ))
                                }
                                </tbody>
                            </Table>
                            : <p className="text-center">No reports available yet</p>
                        }
                    </Preview>
                </Stack>
            </Column>
            <div className="fixed-bottom mb-6 d-flex justify-content-center">
                <div className="d-inline-block">
                    <Button className="rounded-5 m-1 m-md-3 d-block w-100" variant="primary"
                            onClick={() => navigate("/account/kits/add")} data-cy="register-kit">
                        <FontAwesomeIcon icon={faVials} size="lg" className="me-3"/>Register S'wipe Kit
                    </Button>
                    <Button className="rounded-5 m-1 m-md-3 d-block w-100" variant="primary"
                            disabled={numAvailableTests === 0}
                            onClick={() => navigate("/account/test")}>
                        <FontAwesomeIcon icon={faPoo} size="lg" className="me-3"/>Collect Sample
                        ({numAvailableTests} tests available)
                    </Button>
                </div>
            </div>
        </>
    )
}

export default Home;