import {Image, ListGroup} from "react-bootstrap";
import React from "react";
import swipeAndTube from "../../../assets/images/swipe_bag.png"
import StackWithButtons from "../../../components/StackWithButtons";

const Intro = (props: {
    prev: () => void;
    next: () => void;
}) => {
    return (
        <StackWithButtons title="Prepare for Testing" prev={props.prev} next={props.next}>
            <ListGroup>
                <ListGroup.Item>
                    <div className="text-center">
                        <Image src={swipeAndTube} className="bg-primary-lighter bg-gradient border border-primary"
                               width="256px" alt="Swipe and Tube" thumbnail/>
                    </div>
                </ListGroup.Item>
                <ListGroup.Item className="small text-start" variant="light">
                    <ol className="flex-grow-1">
                        <li><strong>Open</strong> S'Wipe bag with a wipe and a collection tube inside.</li>
                        <li><strong>Remember</strong> the number on top of the bag. You'll need to select this number on the next page.</li>
                        <li><strong>Use</strong> the wipe after a bowel movement, just like regular toilet paper.</li>
                        <li><strong>Place</strong> the used wipe into the collection tube with the preservation fluid.</li>
                        <li>Securely <strong>close</strong> the tube to avoid any leaks during shipping.</li>
                        <li><strong>Shake</strong> the tube vigorously for 5-10 seconds.</li>
                    </ol>
                    <div className="text-end">
                        <a href="https://s-wipe.shop/instructions" target="_blank" rel="noreferrer">Read more...</a>
                    </div>
                </ListGroup.Item>
            </ListGroup>
        </StackWithButtons>
    )
}

export default Intro;