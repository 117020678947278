import React, {useEffect, useMemo} from "react"
import {useUserAuth} from "../../../contexts/UserAuthContext";
import {Button, Card, Container, Form, Row, Stack, Table} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import TableWithPages from "../../../components/TableWithPages";
import {ColumnDef} from "@tanstack/react-table";
import {getTubesByBarcodeIds} from "../../../firebase";
import Tube from "../../../models/Tube";
import Col from "react-bootstrap/Col";
import {searchBarcodes, searchUsers} from "../../../s-wipe-api";

const Admin = () => {

    const {apiToken, logOut} = useUserAuth();
    const navigate = useNavigate();
    // const [numBarcodes, setNumBarcodes] = React.useState<number>();
    // const [numUsers, setNumUsers] = React.useState<number>();
    const [tubes, setTubes] = React.useState<Array<Tube>>([]);
    const [users, setUsers] = React.useState([]);
    const [searchText, setSearchText] = React.useState<string>('');

    // countDocuments('barcodes')
    //     .then((count: number) => setNumBarcodes(count));
    //
    // countDocuments('users')
    //     .then((count: number) => setNumUsers(count));

    useEffect(() => {
        if (!!apiToken && !!searchText && searchText.length > 2) {
            searchBarcodes(apiToken, searchText).then(barcodeIds => {
                getTubesByBarcodeIds(barcodeIds).then(result => setTubes(result));
            });
            searchUsers(apiToken, searchText).then(users => {
                const userRows = [];
                for (const user of users) {
                    if (!user.kits) {
                        userRows.push({
                            email: user.email,
                            firstName: user.firstName,
                            lastName: user.lastName,
                        })
                    } else {
                        for (const kit of user.kits) {
                            if (!kit.tests) {
                                userRows.push({
                                    email: user.email,
                                    firstName: user.firstName,
                                    lastName: user.lastName,
                                    kitId: kit.kitId,
                                })
                            } else {
                                for (const test of kit.tests) {
                                    userRows.push({
                                        email: user.email,
                                        firstName: user.firstName,
                                        lastName: user.lastName,
                                        kitId: kit.kitId,
                                        barcode: test.barcode,
                                        samplerVialId: test.samplerVialId,
                                        storageVialId: test.storageVialId,
                                    });
                                }
                            }
                        }
                    }
                }
                setUsers(userRows);
            });
        } else {
            setTubes([]);
            setUsers([]);
        }

    }, [apiToken, searchText]);

    const tubeColumns = useMemo<ColumnDef<any>[]>(() => [
        {
            accessorKey: 'tubeId',
            header: 'Tube'
        },
        {
            accessorKey: 'kitId',
            header: 'Kit',
        },
        {
            accessorKey: 'tubeNumber',
            header: 'Tube Number',
        },
        {
            accessorKey: 'mass',
            header: 'Mass',
        },
        {
            accessorKey: 'customerName',
            header: 'Customer Name',
        },
        {
            accessorKey: 'customerEmail',
            header: 'Customer Email',
        },
        {
            accessorKey: 'customerTracking',
            header: 'Customer Tracking',
        },
        {
            accessorKey: 'returnTracking',
            header: 'ReturnTracking',
        }
    ], []);

    const userColumns = useMemo<ColumnDef<any>[]>(() => [
        {
            accessorKey: 'email',
            header: 'Email',
        },
        {
            accessorKey: 'firstName',
            header: 'First Name',
        },
        {
            accessorKey: 'lastName',
            header: 'Last Name',
        },
        {
            accessorKey: 'kitId',
            header: 'Kit ID',
        },
        {
            accessorKey: 'barcode',
            header: 'Tube ID',
        },
        {
            accessorKey: 'samplerVialId',
            header: 'Sampler Vial ID',
        },
        {
            accessorKey: 'storageVialId',
            header: 'Storage Vial ID',
        }
    ], []);

    if (!apiToken) {
        logOut().then(() => navigate("/login"));
        return;
    }

    return (
        <Stack className="m-3 gap-md-5 m-md-5" gap={3}>

            <Card className="shadow">
                <Card.Header>
                    <Card.Title>Search</Card.Title>
                    <Card.Text>
                        <Form.Control type="text"
                                      placeholder="Search barcode, customer info, tracking info, or tube IDs"
                                      value={searchText}
                                      onChange={e => setSearchText(e.target.value)}/>
                    </Card.Text>
                </Card.Header>
                <Card.Body>
                    {(!searchText || searchText.length <= 2) && (
                        <p>Enter at least 3 characters to search</p>
                    )}
                    {!!searchText && searchText.length > 2 && (
                        <Stack direction="vertical" gap={3}>
                            <div>
                                <h5>Barcodes matching the search</h5>
                                <TableWithPages data={tubes} columns={tubeColumns} className="small"/>
                            </div>
                            <div>
                                <h5 className="mt-3">Users matching the search</h5>
                                <TableWithPages data={users} columns={userColumns} className="small"/>
                            </div>
                        </Stack>
                    )}
                </Card.Body>
            </Card>

            <Card className="shadow">
                <Card.Header>
                    <Card.Title>Actions</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Card.Link href="/account/admin/scan"><Button>Verify Testing Kit...</Button></Card.Link>
                    <Card.Link href="/account/admin/user/add"><Button>Create User...</Button></Card.Link>
                </Card.Body>
            </Card>
        </Stack>
    )
}

export default Admin;