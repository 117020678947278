import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table } from 'react-bootstrap';
import { useUserAuth } from "../../../contexts/UserAuthContext";
import StackWithButtons from "../../../components/StackWithButtons";
import Column from '../../../components/Column';
import Preview from '../../../components/Preview';

const UserInformation = ({ prev, next }: {
    prev: () => void,
    next: () => void,
}) => {
    const { userData } = useUserAuth();
    const navigate = useNavigate();

    return (
        <Column>
            <StackWithButtons title="Your Profile" prev={prev} next={next}>
                <Preview header='Account Information'>
                    <Table className="table-sm table-borderless m-0" data-cy="account-info">
                        <tbody>
                            <tr>
                                <th>First Name</th>
                                <td data-cy="first-name">{userData?.firstName}</td>
                            </tr>
                            <tr>
                                <th>Last Name</th>
                                <td data-cy="last-name">{userData?.lastName}</td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td data-cy="email">{userData?.email}</td>
                            </tr>
                            <tr>
                                <th>Gender</th>
                                <td data-cy="gender">{userData?.gender}</td>
                            </tr>
                            <tr>
                                <th>Date of Birth</th>
                                <td data-cy="date-of-birth">{userData?.dateOfBirth}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Preview>
                <Preview header="Health Information">
                    <Table className="table-sm table-borderless m-0" data-cy="health-info">
                        <tbody>
                            <tr>
                                <th>Weight</th>
                                <td data-cy="weight">{userData?.weight} {userData?.weightUnit}</td>
                            </tr>
                            <tr>
                                <th>Height</th>
                                <td data-cy="height">{userData?.height?.toFixed(2)} {userData?.heightUnit}</td>
                            </tr>
                            <tr>
                                <th>Diet</th>
                                <td data-cy="diet">{userData?.diets?.join(', ')}</td>
                            </tr>
                            <tr>
                                <th>Gut Conditions</th>
                                <td data-cy="gut-conditions">{userData?.gutConditions?.length ? userData?.gutConditions.join(', ') : 'None'}</td>
                            </tr>
                            <tr>
                                <th>Gut Symptoms</th>
                                <td data-cy="gut-symptoms">{userData?.gutSymptoms?.length ? userData?.gutSymptoms.join(', ') : 'None'}</td>
                            </tr>
                            <tr>
                                <th>Other Medical Conditions</th>
                                <td data-cy="other-conditions">{userData?.otherConditions?.length ? userData?.otherConditions : 'None'}</td>
                            </tr>
                            <tr>
                                <th>Medications and Supplements</th>
                                <td data-cy="medications">{userData?.medications?.length ? userData?.medications : 'None'}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Preview>
            </StackWithButtons>
            <div className="fixed-bottom mb-6 d-flex justify-content-center">
                <div className="d-inline-block">
                    <Button className="rounded-5 m-1 m-md-3 d-block w-100" variant="primary" data-cy="edit-profile"
                            onClick={() => navigate("/account/details/edit")}>
                        Edit Profile
                    </Button>
                </div>
            </div>
        </Column>
    );
}

export default UserInformation;
