import React, {useRef} from 'react';
import {useUserAuth} from "../../../contexts/UserAuthContext";
import Intro from "./Intro";
import TestSelector from "./TestSelector";
import BristolChart from "./BristolChart";
import PageWizard from "../../../components/PageWizard";
import {updateUserTest} from "../../../firebase";
import Test from "../../../models/Test";
import {dateToString} from "../../../utils/Utils";
import PackAndShip from "./PackAndShip";
import ThankYou from "./ThankYou";
import CollectSample from "./CollectSample";
import AskAboutStool from "./AskAboutStool";


const TestWizard = () => {

    const pageWizardRef = useRef<{
        prev: () => void;
        next: () => void;
    }>(null);

    const prev = () => pageWizardRef.current?.prev();
    const next = () => pageWizardRef.current?.next();

    const {user} = useUserAuth();
    const [barcode, setBarcode] = React.useState<string>();
    const [kitId, setKitId] = React.useState<string>();

    function handleTestSelector(test: Test) {
        if (!user.uid || !test) return;

        if (!!test.date) {
            const confirmation = window.confirm(
                `This test was already used on ${dateToString(test.date)}. Do you want to overwrite the previous data?`);
            if (!confirmation) return;
        }

        updateUserTest(user.uid, test.kitId, test.testId, {date: new Date()})
            .then(() => {
                setBarcode(test.testId);
                setKitId(test.kitId);
                next();
            })
    }

    return (
        <PageWizard ref={pageWizardRef}>
            <AskAboutStool next={next}/>
            <Intro prev={prev} next={next} />
            {/*<CollectSample prev={prev} next={next} />*/}
            <TestSelector prev={prev} next={handleTestSelector}/>
            <BristolChart prev={prev} next={(x) => {
                updateUserTest(user.uid, kitId, barcode, {bristolStoolType: x})
                    .then(next);
            }}/>
            <PackAndShip prev={prev} next={next}/>
            <ThankYou prev={prev}/>
        </PageWizard>
    )
}

export default TestWizard;