import React from 'react';
import {Stack} from "react-bootstrap";
import Button from "react-bootstrap/Button";

const StackWithButtons = ({title, description, prev, next, isNextAvailable, children, className}: {
    title: string,
    description?: string,
    prev?: () => void,
    next?: () => void,
    isNextAvailable?: boolean,
    children?: React.ReactNode,
    className?: string,
}) => {

    if (isNextAvailable === undefined) {
        isNextAvailable = true;
    }

    return (
        <Stack className={"my-1 my-md-3 gap-md-3 " + (className ?? '')} gap={1}>
            {!!title &&
                <h3 className="text-center flex-grow-1">{title}</h3>
            }
            {!!description &&
                <p>{description}</p>
            }
            {children}
            <Stack direction="horizontal" className="my-3" gap={3}>
                {!!prev &&
                    <Button className="w-50" variant="secondary" onClick={prev}>Previous</Button>
                }
                {!!next &&
                    <Button className="ms-auto w-50" onClick={next} disabled={!isNextAvailable}>Next</Button>
                }
            </Stack>
        </Stack>
    )
}

export default StackWithButtons;