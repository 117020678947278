import {Navigate, Outlet, useNavigate} from "react-router-dom";
import {useUserAuth} from "../contexts/UserAuthContext";
import {Badge, Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import swipeLogo from "../assets/images/s-wipe-logo_20240805_white.png";
import {
    faBell,
    faCloudArrowDown,
    faFileLines,
    faHome,
    faRightFromBracket,
    faVials,
    faUser,
    faUserTie
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import {useEffect, useState} from "react";
import {countUserUnreadMessages} from "../firebase";
import {checkAllUserData} from "../models/User";

const ProtectedRoutes = () => {
    const {user, userData, logOut} = useUserAuth();
    const [numMessages, setNumMessages] = useState(0);
    const [supportsInstall, setSupportsInstall] = useState(false);
    const [installPrompt, setInstallPrompt] = useState(null);

    useEffect(() => {
        const handler = event => {
            event.preventDefault();
            setSupportsInstall(true);
            setInstallPrompt(event);
        };
        window.addEventListener("beforeinstallprompt", handler);

        return () => window.removeEventListener("beforeinstallprompt", handler);
    }, [])

    useEffect(() => {
        if (!!user?.uid) {
            countUserUnreadMessages(user.uid)
                .then((count) => setNumMessages(count));
        }
    }, [user]);

    if (user === null || user === undefined || Object.keys(user).length === 0) {
        return <Navigate to="/"/>;
        // navigate("/");
        // return;
    }

    if (!user?.emailVerified) {
        return <Navigate to="/verify-email"/>;
        // navigate("/verify-email");
        // return;
    }

    return (
        <>
            <Navbar expand="lg" className="bg-primary-dark text-white" variant="dark">
                <Container>
                    <Navbar.Brand href="/account">
                        <img src={swipeLogo} alt="S'Wipe" height="32px"/>
                        <Button variant="secondary" size="sm" className="ms-1 ms-md-3 rounded-5"
                                hidden={!supportsInstall}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (!installPrompt) return;
                                    installPrompt.prompt();
                                }}>
                            <FontAwesomeIcon icon={faCloudArrowDown} size="lg" className="me-1"/>
                            Install
                        </Button>
                    </Navbar.Brand>
                    <Navbar.Toggle className="position-relative" aria-controls="basic-navbar-nav">
                        <span className="navbar-toggler-icon"/>
                        {numMessages > 0 &&
                            <span className="position-absolute top-0 start-100 translate-middle badge
                                            bg-secondary border border-light rounded-circle p-0"
                                  style={{height: 16, width: 16}}>
                            <span className="visually-hidden">New messages</span>
                        </span>
                        }
                    </Navbar.Toggle>


                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav>
                            <Nav.Link href="/account">
                                <FontAwesomeIcon icon={faHome} size="sm" className="me-1"/>Home
                            </Nav.Link>
                            <Nav.Link href="/account/kits">
                                <FontAwesomeIcon icon={faVials} size="sm" className="me-1"/>Test Kits
                            </Nav.Link>
                            <Nav.Link href="/account/reports">
                                <FontAwesomeIcon icon={faFileLines} size="sm" className="me-1"/>Reports
                            </Nav.Link>
                            <Nav.Link href="/account/messages">
                                <FontAwesomeIcon icon={faBell} size="sm" className="me-1"/>Messages
                                {numMessages > 0 &&
                                    <Badge bg="secondary" className="ms-2 text-light">{numMessages}</Badge>
                                }
                            </Nav.Link>
                            {user.isAdmin &&
                                <Nav.Link href="/account/admin" className="border border-danger">
                                    <FontAwesomeIcon icon={faUserTie} size="sm" className="me-1"/>Admin
                                </Nav.Link>
                            }
                            <NavDropdown title="Account" menuVariant="dark" data-cy="account">
                                <NavDropdown.Item href="/account/details">
                                    <FontAwesomeIcon icon={faUser} size="sm" className="me-1"/>Profile
                                </NavDropdown.Item>
                                <NavDropdown.Divider/>
                                <NavDropdown.Item href="/" onClick={() => logOut()} data-cy="logout">
                                    <FontAwesomeIcon icon={faRightFromBracket} size="sm" className="me-1"/>Log out
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Outlet/>
            <Navbar fixed="bottom" className="bg-dark" variant="dark">
                <Nav fill className="small" style={{width: "100%"}}>
                    <Nav.Link href="/account">
                        <FontAwesomeIcon icon={faHome} size="lg"/>&nbsp;Home
                    </Nav.Link>
                    <Nav.Link href="/account/kits">
                        <FontAwesomeIcon icon={faVials} size="lg"/>&nbsp;Test Kits
                    </Nav.Link>
                    <Nav.Link href="/account/reports">
                        <FontAwesomeIcon icon={faFileLines} size="lg"/>&nbsp;Reports
                    </Nav.Link>
                    <Nav.Link href="/account/details" data-cy="profile">
                        <FontAwesomeIcon icon={faUser} size="lg"/>&nbsp;Profile
                    </Nav.Link>
                </Nav>
            </Navbar>
        </>
    )
}

export default ProtectedRoutes;