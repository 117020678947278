import React, {useState} from "react";
import StackWithButtons from "../../../components/StackWithButtons";
import MultiOptionSelector from "../../../components/MultiOptionSelector";
import {useUserAuth} from "../../../contexts/UserAuthContext";

const Race = (props: {
    prev: () => void;
    next: () => void;
}) => {
    const {updateUserData, userData} = useUserAuth();
    const races = ["American Indian or Alaskan Native", "Asian", "Black or African American",
        "Native Hawaiian or Pacific Islander", "White", "Hispanic or Latino", "Other"];
    const [selectedRaces, setSelectedRaces] = useState(userData.race);
    // const raceButtons = races.map((race) =>
    //     <ToggleButton id={race.replace(' ', '-').toLowerCase()}
    //                   key={race.replace(' ', '-').toLowerCase()}
    //                   value={race} type="checkbox" className="rounded-5" variant="outline-dark"
    //                   checked={selectedRaces !== undefined && selectedRaces.indexOf(race) > -1}
    //                   onChange={(e) => {
    //                       const selected = selectedRaces !== undefined ? selectedRaces.slice() : [];
    //                       const index = selected.indexOf(race);
    //                       if (index > -1) {
    //                           selected.splice(index, 1);
    //                       } else {
    //                           selected.push(race);
    //                       }
    //                       setSelectedRaces(selected);
    //                   }}>
    //         {race}
    //     </ToggleButton>
    // )

    function handleClick() {
        updateUserData("race", selectedRaces)
            .then(() => props.next());
    }

    return (
        <StackWithButtons title="Race/Ethnicity"
                          description="Which of the following racial backgrounds apply to you? You can choose more than one."
                          prev={props.prev} next={handleClick}
                          isNextAvailable={!!selectedRaces && selectedRaces.length > 0}>
            <MultiOptionSelector options={races} selectedOptions={selectedRaces} setSelectedOptions={setSelectedRaces}/>
        </StackWithButtons>
    )
    // return (
    //     <>
    //         <Stack className="overflow-y-auto" gap={1} style={{height: '100%'}}>
    //             <h3 className="flex-grow-1">What is your Race/Ethnicity?</h3>
    //             <p>Which of the following racial backgrounds apply to you? You can choose more than one.</p>
    //             {raceButtons}
    //             <span className="flex-grow-1"/>
    //         </Stack>
    //         <Stack direction="horizontal" gap={3}>
    //             <Button className="w-50" variant="secondary" onClick={prev}>Previous</Button>
    //             <Button className="w-50" onClick={handleClick}
    //                     disabled={selectedRaces === undefined || selectedRaces.length === 0}>Next</Button>
    //         </Stack>
    //     </>
    // )
}

export default Race;