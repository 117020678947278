import React, {useEffect, useState} from 'react';
import {Form, InputGroup, Stack} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useUserAuth} from "../../../contexts/UserAuthContext";
import {checkNameFields} from "../../../models/User";
import StackWithButtons from "../../../components/StackWithButtons";

const Name = (props: {
    next: () => void;
}) => {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const {userData, updateUserData} = useUserAuth();
    const hasNameFields = checkNameFields(userData);

    useEffect(() => {
        if (hasNameFields) {
            props.next();
        }
    }, [hasNameFields, props])

    if (hasNameFields) {
        return null;
    }

    const handleClick = () => {
        updateUserData('firstName', firstName, 'lastName', lastName)
            .then((success: boolean) => {
                if (success) {
                    props.next();
                }
            })
    }

    return (
        <StackWithButtons title="Your Name" description="Please, input your name" next={handleClick}
                          isNextAvailable={!!firstName && !!lastName}>
            <InputGroup>
                <Form.Control type="text" name="firstName" value={firstName}
                              onChange={(e) => setFirstName(e.target.value?.trim())}
                              placeholder="First name"/>
            </InputGroup>
            <InputGroup>
                <Form.Control type="text" name="lastName" value={lastName}
                              onChange={(e) => setLastName(e.target.value?.trim())}
                              placeholder="Last name"/>
            </InputGroup>
            {/*<Stack gap={1} className="overflow-y-auto" style={{height: '100%'}}>*/}
            {/*    <h3 className="flex-grow-1">Your Name</h3>*/}
            {/*    <p>Please, input your name</p>*/}
            {/*    */}

            {/*</Stack>*/}
            {/*<Stack direction="horizontal" gap={3}>*/}
            {/*    <Button className="ms-auto w-50" onClick={() => props.next(firstName, lastName)}*/}
            {/*            disabled={!firstName || !lastName}>Next</Button>*/}
            {/*</Stack>*/}
        </StackWithButtons>
    )
}

export default Name;