import React from 'react';
import {Form, ToggleButton} from "react-bootstrap";
import {useUserAuth} from "../../../contexts/UserAuthContext";
import {useState} from "react";
import StackWithButtons from "../../../components/StackWithButtons";

const OtherConditions = ({prev, next}: {
    prev: () => void,
    next: () => void,
}) => {

    const {updateUserData, userData} = useUserAuth();
    const [conditions, setConditions] = useState(userData.otherConditions ?? '');

    function handleNext() {
        updateUserData('otherConditions', conditions)
            .then((result: boolean) => {
                if (result) {
                    next();
                }
            });
    }

    return (
        <>
            <StackWithButtons title="Other Medical Conditions"
                              description="Do you have any other medical conditions or concerns?"
                              prev={prev} next={handleNext}>
                <ToggleButton id="none" value="none" type="checkbox" checked={!conditions} variant="outline-primary-dark"
                              className="rounded-5"
                    onChange={(e) => {
                        if (e.currentTarget.checked) {
                            setConditions('');
                        }
                    }}>
                    None
                </ToggleButton>
                <Form.Group className="flex-grow-1 d-flex flex-column">
                    <Form.Control id="textarea" as="textarea" className="flex-grow-1" maxLength={200} style={{height: '200px'}}
                                  placeholder="Please specify any other medical conditions or concerns (max 200 characters)"
                                    value={conditions}
                                  onChange={(e) => {
                                      setConditions(e.currentTarget.value);
                                  }}/>
                    <p className="small">{conditions === undefined || conditions === null ? 0 : conditions.length}/200</p>
                </Form.Group>
            </StackWithButtons>
        </>
    )
}

export default OtherConditions;
