import {Button, Image, ListGroup, Modal, Stack} from "react-bootstrap";
import React from "react";
// import swipeAndTube from "../../../assets/images/swipe_bag.png";
import poopIcon from "../../../assets/images/poop_icon.jpg";
import StackWithButtons from "../../../components/StackWithButtons";
import SingleOptionSelector from "../../../components/SingleOptionSelector";

const AskAboutStool = (props: {
    next: () => void;
}) => {

    const options = ["Yes", "No"];
    const [selectedOption, setSelectedOption] = React.useState<string | null>();
    const [showAlert, setShowAlert] = React.useState(false);

    function handleNext() {
        if (selectedOption === "Yes") {
            props.next();
        } else {
            setShowAlert(true);
        }
    }

    return (
        <>
            <Modal show={showAlert} onHide={() => setShowAlert(false)}>
                <Modal.Body>
                    If you cannot see stool sample on the wipe, this may affect the accuracy of your test results.
                    Do you want to proceed with sampling anyway?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAlert(false)}>Close</Button>
                    <Button variant="primary" onClick={() => {
                        setShowAlert(false);
                        props.next();
                    }}>Proceed</Button>
                </Modal.Footer>
            </Modal>

            <StackWithButtons title="Stool Sampling" next={handleNext} isNextAvailable={!!selectedOption}>
                <ListGroup>
                    <ListGroup.Item>
                        <div className="text-center">
                            <Image src={poopIcon} className="bg-primary-lighter bg-gradient border border-primary"
                                   width="256px" alt="Stool sampling" thumbnail/>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="small text-start" variant="light">
                        <Stack direction="vertical" className="gap-md-3" gap={1}>
                            <p>
                                When you wipe with regular toilet paper, do you usually <strong>observe
                                stool</strong> on
                                it?
                            </p>
                            <SingleOptionSelector id="stool-sampling"
                                                  options={options} value={selectedOption}
                                                  setValue={setSelectedOption}/>
                            {selectedOption === "Yes" && (
                                <p className="text-center">Proceed with sampling</p>
                            )}
                            {selectedOption === "No" && (
                                <>
                                    Consider collecting your stool sample after eating:
                                    <ul>
                                        <li>foods reach in insoluble fiber, like whole-grain and wheat bread, oatmeal,
                                            and brown rice;
                                        </li>
                                        <li>plant-based foods, like broccoli, carrots, apples (with skin), almonds, and
                                            lentils.
                                        </li>
                                    </ul>
                                </>
                            )}
                            {/*<div className="text-end">*/}
                            {/*    <a href="https://s-wipe.shop/instructions" target="_blank" rel="noreferrer">Read*/}
                            {/*        more...</a>*/}
                            {/*</div>*/}
                        </Stack>
                    </ListGroup.Item>
                </ListGroup>
            </StackWithButtons>
        </>
    )
}

export default AskAboutStool;