import React, { useEffect } from 'react';
import StackWithButtons from "../../../../components/StackWithButtons";
import RoundedButton from "../../../../components/RoundedButton";
import { Link } from "react-router-dom";
import { getUserKit } from "../../../../firebase";
import Kit from "../../../../models/Kit";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPoo } from '@fortawesome/free-solid-svg-icons';

const Finish = (props: {
    prev: () => void;
    next: () => void;
    uid: string;
    kitId: string;
}) => {

    const [kit, setKit] = React.useState<Kit>();
    const navigate = useNavigate();

    useEffect(() => {
        getUserKit(props.uid, props.kitId)
            .then((kit) => {
                if (!!kit) {
                    setKit(kit);
                }
            })
    }, [props.uid, props.kitId]);

    return (
        <StackWithButtons title="Continue with Testing" className="my-5 text-start">
            <p>
                You succesfully registered your S'wipe kit, and have {kit?.numTests ?? 0} tests available to you! Use these tests to collect one stool sample after each bowel movement
                or over the course of several days as you see fit.
            </p>
            <p>
                Each time you collect a sample, click the button "Collect Sample" on this webapp and follow the instructions to collect the sample.
            </p>
            {/* <RoundedButton onClick={props.next} disabled={!kit} className="mt-5">
                Begin Testing
            </RoundedButton> */}
            <RoundedButton variant="primary"
                disabled={kit?.numTests === 0}
                onClick={() => navigate("/account/test")}>
                <FontAwesomeIcon icon={faPoo} size="lg" className="me-3" />Collect Sample ({kit?.numTests} tests available)
            </RoundedButton>
            <Link to="/account" className="mb-5">
                <RoundedButton variant="secondary">
                    Collect Later
                </RoundedButton>
            </Link>
        </StackWithButtons>
    )
}

export default Finish;