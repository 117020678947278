import React, {useState} from 'react';
import StackWithButtons from "../../../components/StackWithButtons";
import MultiOptionSelector, {MultiOption} from "../../../components/MultiOptionSelector";
import {useUserAuth} from "../../../contexts/UserAuthContext";

const GutConditions = ({prev, next}: {
    prev: () => void,
    next: () => void,
}) => {

    const {updateUserData, userData} = useUserAuth();
    const gutConditions = [
        new MultiOption("Irritable Bowel Syndrome (IBS)", undefined, undefined, "A common disorder that affects the large intestine, causing cramping, abdominal pain, bloating, gas, and diarrhea or constipation."),
        new MultiOption("Inflammatory Bowel Disease (IBD)", undefined, undefined, "A term for two conditions (Crohn's disease and ulcerative colitis) that are characterized by chronic inflammation of the gastrointestinal (GI) tract."),
        new MultiOption("Crohn's disease", undefined, undefined, "A type of inflammatory bowel disease (IBD) that can affect any part of the gastrointestinal tract, causing a wide range of symptoms."),
        new MultiOption("Small Intestinal Bacterial Overgrowth (SIBO)", undefined, undefined, "A condition where excessive bacteria grow in the small intestine, leading to symptoms like bloating, diarrhea, and abdominal pain."),
        new MultiOption("Celiac Disease", undefined, undefined, "An immune reaction to eating gluten, a protein found in wheat, barley, and rye, which can damage the small intestine."),
        new MultiOption("Gastroesophageal Reflux Disease (GERD)", undefined, undefined, "A chronic digestive disease where stomach acid or bile irritates the food pipe lining, causing heartburn and other symptoms.")
    ];
    const [selectedConditions, setSelectedConditions] = useState<Array<string>>(userData.gutConditions ?? []);

    function handleNext() {
        updateUserData('gutConditions', selectedConditions)
        .then((result: boolean) => {
            if (result) {
                next();
            }
        });
    }

    return (
        <StackWithButtons title="Gut Conditions"
                          description="Please select if you have any of the following gut conditions:"
                          prev={prev} next={handleNext}>
            <MultiOptionSelector options={gutConditions} selectedOptions={selectedConditions}
                                 setSelectedOptions={setSelectedConditions} hasOtherOption={true} hasNoneOption={true}/>
        </StackWithButtons>
    )
}

export default GutConditions;