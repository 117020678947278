import React from 'react';
import {Form, ToggleButton} from "react-bootstrap";
import {useUserAuth} from "../../../contexts/UserAuthContext";
import {useState} from "react";
import StackWithButtons from "../../../components/StackWithButtons";

const Medications = ({prev, next}: {
    prev: () => void,
    next: () => void,
}) => {

    const {updateUserData, userData} = useUserAuth();
    const [medications, setMedications] = useState(userData.medications ?? '');

    function handleNext() {
        updateUserData('medications', medications)
            .then((result: boolean) => {
                if (result) {
                    next();
                }
            });
    }

    return (
        <>
            <StackWithButtons title="Medications and Supplements"
                              description="Are you regularly taking any medications or supplemetents (e.g., antacids, herbal tea, laxatives)"
                              prev={prev} next={handleNext}>
                <ToggleButton id="none" value="none" type="checkbox" checked={!medications} variant="outline-primary-dark"
                              className="rounded-5"
                    onChange={(e) => {
                        if (e.currentTarget.checked) {
                            setMedications('');
                        }
                    }}>
                    None
                </ToggleButton>
                <Form.Group className="flex-grow-1 d-flex flex-column">
                    <Form.Control id="textarea" as="textarea" className="flex-grow-1" maxLength={200} style={{height: '200px'}}
                                  placeholder="Please specify any medications/supplements that you currently take (max 200 characters)"
                                    value={medications}
                                  onChange={(e) => {
                                      setMedications(e.currentTarget.value);
                                  }}/>
                    <p className="small">{medications === undefined || medications === null ? 0 : medications.length}/200</p>
                </Form.Group>
            </StackWithButtons>
        </>
    )
}

export default Medications;