import React, {useState, useEffect} from 'react';
import {Form, ToggleButton, Overlay} from 'react-bootstrap';
import Tooltip from "react-bootstrap/Tooltip";
import {faCircleInfo, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const MultiOptionSelector = (
    {options, selectedOptions, setSelectedOptions, hasOtherOption, hasNoneOption}: {
        options: Array<string> | Array<MultiOption>,
        selectedOptions: Array<string>,
        setSelectedOptions: (selectedOptions: Array<string>) => void,
        hasOtherOption?: boolean,
        hasNoneOption?: boolean,
    }) => {

    if (hasOtherOption === undefined) {
        hasOtherOption = false;
    }

    if (hasNoneOption === undefined) {
        hasNoneOption = false;
    }

    if (options.every(item => typeof item === "string")) {
        options = options.map(name => new MultiOption(name));
    }

    const otherOptions = selectedOptions?.filter(x => !options
        .map((o: string | MultiOption) => o.toString())
        .includes(x));
    const [otherOption, setOtherOption] = useState<string>(otherOptions?.length > 0 ? otherOptions[0] : '');
    const [includeOther, setIncludeOther] = useState<boolean>(otherOptions?.length > 0);
    const [tooltipTarget, setTooltipTarget] = useState<HTMLElement | null>(null);
    const [tooltipContent, setTooltipContent] = useState<string | null>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (tooltipTarget && !(tooltipTarget as HTMLElement).contains(event.target as Node)) {
                setTooltipTarget(null);
                setTooltipContent(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [tooltipTarget]);

    const updateSelectedOptions =
        (oldValue: string, newValue: string, addValue: boolean) => {
            const selected = selectedOptions !== undefined ? selectedOptions.slice() : [];

            // Remove old value from selected
            const index = selected.indexOf(oldValue);
            if (index > -1) {
                selected.splice(index, 1);
            }

            if (addValue) {  // Add new value
                selected.push(newValue);
            }

            // Update selected options
            setSelectedOptions(selected);
        };

    return (
        <>
            {hasNoneOption &&
                <ToggleButton id="None" key="None" value="None" type="checkbox" className="rounded-5"
                              variant="outline-primary-dark"
                              checked={selectedOptions === undefined || selectedOptions.length === 0}
                              onChange={(e) => {
                                  if (e.target.checked) {
                                      setSelectedOptions([]);
                                  }
                              }}>
                    None
                </ToggleButton>
            }
            {
                options.map((option) =>
                    <ToggleButton id={option.name.replace(' ', '-').toLowerCase()}
                                  key={option.name.replace(' ', '-').toLowerCase()}
                                  value={option.name} type="checkbox" className="d-flex align-items-center rounded-5"
                                  variant="outline-primary-dark"
                                  checked={selectedOptions !== undefined && selectedOptions.indexOf(option.name) > -1}
                                  onChange={(e) => {
                                      updateSelectedOptions(option.name, option.name, !selectedOptions?.includes(option.name));
                                  }}>
                        {option.icon &&
                            <FontAwesomeIcon icon={option.icon}
                                             color={selectedOptions.indexOf(option.name) > -1 ? "white" : option.iconColor}/>
                        }
                        <span className="flex-grow-1">{option.name}</span>
                        {option.description &&
                            <div onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (tooltipTarget !== e.currentTarget) {
                                    setTooltipTarget(e.currentTarget);
                                    setTooltipContent(option.description);
                                } else {
                                    setTooltipTarget(null);
                                    setTooltipContent(null);
                                }
                            }} onMouseOver={(e) => {
                                setTooltipTarget(e.currentTarget);
                                setTooltipContent(option.description);
                            }} onMouseOut={() => {
                                setTooltipTarget(null);
                                setTooltipContent(null);
                            }}>
                                <FontAwesomeIcon icon={faCircleInfo} size="lg"/>
                            </div>
                        }
                    </ToggleButton>
                )
            }
            {hasOtherOption &&
                <Form.Control type="text" placeholder="Other" key="Other"
                              className={"rounded-5 text-center " + (includeOther ? "bg-primary-dark text-light" : "bg-light text-dark")}
                              value={includeOther ? otherOption : ''}
                              onClick={() => {
                                  updateSelectedOptions(otherOption, otherOption, !includeOther);
                                  setIncludeOther(!includeOther);
                              }}
                              onChange={(e) => {
                                  const value = (e.target as HTMLInputElement).value;
                                  updateSelectedOptions(otherOption, value, includeOther);
                                  setOtherOption(value);
                                  setIncludeOther(!!value);
                              }}
                />
            }

            <Overlay target={tooltipTarget} show={!!tooltipTarget} placement="left">
                {(props) => (
                    <Tooltip id="overlay-tooltip" {...props}>
                        {tooltipContent}
                    </Tooltip>
                )}
            </Overlay>
        </>
    )
}


export class MultiOption {
    name: string;
    icon?: IconDefinition;
    iconColor?: string;
    description?: string;

    constructor(name: string, icon?: IconDefinition, iconColor?: string, description?: string) {
        this.name = name;
        this.icon = icon;
        this.iconColor = iconColor;
        this.description = description;
    }

    public toString() {
        return this.name;
    }
}


export default MultiOptionSelector;