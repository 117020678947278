import {useState} from 'react';
import {ButtonGroup, Form, Stack, ToggleButton} from 'react-bootstrap';
import {GenderMale, GenderFemale} from 'react-bootstrap-icons';
import Button from "react-bootstrap/Button";
import {useUserAuth} from "../../../contexts/UserAuthContext";
import StackWithButtons from "../../../components/StackWithButtons";
import React from 'react';
import MultiOptionSelector from "../../../components/MultiOptionSelector";
import SingleOptionSelector from "../../../components/SingleOptionSelector";


const Gender = (props: {
    prev: () => void;
    next: () => void;
}) => {
    const {updateUserData, userData} = useUserAuth();
    const [sex, setSex] = useState<string>(userData.sex);
    const sexOptions = ["Male", "Female"];
    const [gender, setGender] = useState<string>(userData.gender);
    const genderOptions = ['Male', 'Female', 'Non-binary', 'Transgender male', 'Transgender female'];

    function handleClick() {
        updateUserData("sex", sex, "gender", gender)
            .then((success: boolean) => {
                if (success) {
                    props.next();
                }
            });
    }

    return (
        <StackWithButtons title="Sex and Gender" prev={props.prev} next={handleClick} isNextAvailable={!!sex && !!gender}>
            <h5>What was your sex assigned at birth?</h5>
            <SingleOptionSelector id="sex" options={sexOptions} value={sex} setValue={setSex}/>
            <h5>What is your gender?</h5>
            <SingleOptionSelector id="gender" options={genderOptions} value={gender} setValue={setGender}/>
        </StackWithButtons>
    )

    // return (
    //     <>
    //         <Stack gap={1} className="overflow-y-auto" style={{height: '100%'}}>
    //             <h3 className="flex-grow-1">What was your sex assigned at birth?</h3>
    //             <ButtonGroup name="sex">
    //                 <ToggleButton id="male" value="male" name="male" type="radio" variant="outline-primary"
    //                               checked={sex === "male"}
    //                               onChange={(e) => setSex("male")}>
    //                     <GenderMale/>&nbsp;Male
    //                 </ToggleButton>
    //                 <ToggleButton id="female" value="female" name="female" type="radio" variant="outline-primary"
    //                               checked={sex === "female"}
    //                               onChange={() => setSex("female")}>
    //                     <GenderFemale/>&nbsp;Female
    //                 </ToggleButton>
    //             </ButtonGroup>
    //             <h3 className="mt-3">What is your gender?</h3>
    //             {genderOptions.map(genderOption => (
    //                 <ToggleButton id={genderOption} key={genderOption} value={genderOption}
    //                               checked={genderOption === gender}
    //                               type="radio" variant="outline-dark" className="rounded-5"
    //                               onChange={() => setGender(genderOption)}>
    //                     {genderOption}
    //                 </ToggleButton>
    //             ))}
    //             <div className="flex-grow-1">
    //                 <Form.Control type="text" placeholder="Other"
    //                               className={"rounded-5 " + (!!gender && !genderOptions.includes(gender) ? "bg-dark text-light" : "bg-light text-dark")}
    //                               value={!genderOptions.includes(gender) ? gender : ''}
    //                               onFocus={(e) => setGender(e.currentTarget.value)}
    //                               onChange={(e) => setGender(e.currentTarget.value)}/>
    //             </div>
    //
    //         </Stack>
    //         <Stack direction="horizontal" gap={3}>
    //             <Button className="ms-auto w-50" onClick={handleClick}
    //                     disabled={!sex || !gender || gender.length === 0}>Next</Button>
    //         </Stack>
    //     </>
    // )
}

export default Gender;