import React, {ReactNode} from "react";
import {Card} from "react-bootstrap";

const Preview = (props: {
    children: ReactNode,
    header: string,
    href?: string,
    className?: string
}) => {
    return (
        <Card className={"small shadow-sm " + (props.className ?? '')}>
            <Card.Header className="bg-light d-flex justify-content-between fw-bold">
                {props.header}
                {!!props.href &&
                    <Card.Link href={props.href} className="text-primary-dark">More...</Card.Link>
                }
            </Card.Header>
            <Card.Body className="px-2 py-0 d-flex flex-column">
                {props.children}
            </Card.Body>
        </Card>
    )
}

export default Preview;