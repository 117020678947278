import React, {FormEvent} from "react";
import {Card, Form, Stack, Table} from "react-bootstrap";
import Column from "../../../components/Column";
import Button from "react-bootstrap/Button";
import {createUser} from "../../../s-wipe-api";
import {useUserAuth} from "../../../contexts/UserAuthContext";
import {useNavigate} from "react-router-dom";

const AddUser = () => {

    const {apiToken, logOut} = useUserAuth();
    const navigate = useNavigate();
    const [firstName, setFirstName] = React.useState<string>('');
    const [lastName, setLastName] = React.useState<string>('');
    const [email, setEmail] = React.useState<string>('');
    const [kitId, setKitId] = React.useState<string>('');

    if (!apiToken) {
        logOut().then(() => navigate("/login"));
        return;
    }

    function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        createUser(apiToken, firstName, lastName, email, kitId)
            .then((result) => {
                if (result) {
                    console.log(result);
                    alert(`User ${firstName} ${lastName} (${email}) created successfully`);
                    navigate("/account/admin");
                } else {
                    alert('Failed to create user');
                }
            });
    }

    return (
        <Column>
            <Stack direction="vertical" className="py-3" gap={3}>
                <p className="text-danger fw-bold">
                    User will not have access to the created account. So, use this form only when the user is not
                    planning on creating an account or using the webapp!
                </p>
                <Card className="shadow m-3">
                    <Card.Header>
                        <Card.Title>Create User</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form onSubmit={handleSubmit}>
                            <Table className="table-sm" borderless>
                                <tbody>
                                <tr>
                                    <th>First Name</th>
                                    <td>
                                        <Form.Control type="text" placeholder="First Name" required value={firstName}
                                                      onChange={e =>
                                                          setFirstName(e.target.value ?? '')}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Last Name</th>
                                    <td>
                                        <Form.Control type="text" placeholder="Last Name" required value={lastName}
                                                      onChange={e =>
                                                          setLastName(e.target.value ?? '')}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Email</th>
                                    <td>
                                        <Form.Control type="email" placeholder="Email" value={email} required
                                                      onChange={(e) =>
                                                          setEmail(e.target.value ?? '')}
                                        />
                                    </td>
                                    <td>
                                        <Button onClick={() => {
                                            const characters = 'abcdefghijklmnopqrstuvwxyz';
                                            let result = '';
                                            for (let i = 0; i < 10; i++) {
                                                result += characters.charAt(Math.floor(Math.random() * characters.length));
                                            }
                                            setEmail(result + '@example.com');
                                        }}>RND</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <th>S'wipe Kit ID</th>
                                    <td>
                                        <Form.Control type="text" placeholder="S'wipe Kit ID" required value={kitId}
                                                      onChange={e =>
                                                          setKitId(e.target.value ?? '')
                                                      }/>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                            <Button type="submit" className="btn btn-primary">Create User</Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Stack>
        </Column>
    );
}

export default AddUser;