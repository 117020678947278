import React from 'react';
import {ProgressBar, Stack} from "react-bootstrap";
import Gender from "./Gender";
import Race from "./Race";
import Column from "../../../components/Column";
import Biometrics from "./Biometrics";
import Diet from "./Diet";
import Ready from "./Ready";
import GutIssues from "./GutIssues";
import Constipation from "./Constipation";
import GutConditions from "./GutConditions";
import GutSymptoms from "./GutSymptoms";
import Medications from "./Medications";
import Name from "./Name";
import {useUserAuth} from "../../../contexts/UserAuthContext";
import Intro from "./Intro";
import OtherConditions from './OtherConditions';


const Details = () => {

    const [activeStep, setActiveStep] = React.useState(0);

    function next() {
        setActiveStep(activeStep + 1);
    }

    function prev() {
        setActiveStep(activeStep - 1);
    }

    const steps = [
        <Intro next={next}/>,
        <Name next={next}/>,
        <Gender next={next}/>,
        <Race prev={prev} next={next}/>,
        <Biometrics prev={prev} next={next}/>,
        <Diet prev={prev} next={next}/>,
        // <Constipation prev={prev} next={next}/>,
        <GutConditions prev={prev} next={next}/>,
        <GutSymptoms prev={prev} next={next}/>,
        <OtherConditions prev={prev} next={next}/>,
        <Medications prev={prev} next={next}/>,
        // <GutIssues prev={prev} next={next}/>,
        // <Supplements prev={prev} next={next}/>,
        <Ready/>
    ];

    function getSectionComponent() {
        // if (!userData.hasOwnProperty('firstName') || !userData.hasOwnProperty('lastName')) {
        //     return <Name next={(firstName, lastName) => {
        //                      updateUserData('firstName', firstName, 'lastName', lastName)
        //                          .then(() => {
        //                              setActiveStep(-1);
        //                          })
        //                  }}/>;
        // }

        if (activeStep < 0 || activeStep >= steps.length) {
            setActiveStep(0)
        }

        return steps[activeStep];
    }

    return (
        <>
            <ProgressBar now={100 * (1 + activeStep) / steps.length} variant="secondary" style={{height: "5px"}}/>
            <Column>
                <Stack className="d-flex vh-custom p-1 gap-md-3 p-md-3" gap={1}>
                    {getSectionComponent()}
                </Stack>
            </Column>
        </>
    )
}

export default Details;