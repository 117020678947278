import React, {useEffect, useState} from "react";
import StackWithButtons from "../../../components/StackWithButtons";
import RoundedButton from "../../../components/RoundedButton";
import {Link, useNavigate} from "react-router-dom";
import {useUserAuth} from "../../../contexts/UserAuthContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVials} from "@fortawesome/free-solid-svg-icons";
import {checkAllUserData} from "../../../models/User";

const Ready = () => {

    const navigate = useNavigate();
    const {userData} = useUserAuth();
    const hasAllFields = checkAllUserData(userData);

    return (
        <StackWithButtons title="Ready to begin testing?">
            <p>Please, ensure you have the S'wipe Kit ready, and proceed with registering that kit.</p>
            <p>If you don't have the S'wipe Kit yet, you can purchase it
                from <a href="https://s-wipe.shop" target="_blank" rel="noreferrer">https://s-wipe.shop</a>.</p>
            <RoundedButton disabled={!hasAllFields} onClick={() => navigate("/account/kits/add")}>
                <FontAwesomeIcon icon={faVials} size="lg" className="me-3"/>Register S'wipe Kit
            </RoundedButton>
            <Link to="/account"><RoundedButton variant="secondary">Register Later</RoundedButton></Link>
        </StackWithButtons>
    )
}

export default Ready;