import {Stack, ToggleButton} from 'react-bootstrap';
import 'react-multi-carousel/lib/styles.css';
import Button from "react-bootstrap/Button";
import {useUserAuth} from "../../../../contexts/UserAuthContext";
import {useState} from "react";
import StackWithButtons from "../../../../components/StackWithButtons";
import SingleOptionSelector from "../../../../components/SingleOptionSelector";
import React from 'react';

const AntibioticUse = (props: {
    prev: () => void;
    next: (x: string) => void;
}) => {

    // const previousTest = getLastTestExcept(barcode);
    const antibioticUseAnswers = ["Yes, within the past month", "Yes, within the past 3 months",
        "No, not in the past 3 months"];
    const [selectedAntibioticUse, setSelectedAntibioticUse] = useState("No, not in the past 3 months");  // previousTest?.antibioticUse

    return (
        <StackWithButtons title="Antibiotic Use"
                          description="Have you taken any antibiotics in the past three months?"
                          prev={props.prev}
                          next={() => props.next(selectedAntibioticUse)}
                          isNextAvailable={selectedAntibioticUse.length > 0}>
            <SingleOptionSelector id="antibioticUse" options={antibioticUseAnswers} value={selectedAntibioticUse}
                                  setValue={setSelectedAntibioticUse}/>
        </StackWithButtons>
    )
}

export default AntibioticUse;