import React, {useEffect} from 'react';
import StackWithButtons from "../../../components/StackWithButtons";
import logo from "../../../assets/images/s-wipe-logo.png";
import { useUserAuth } from '../../../contexts/UserAuthContext';
import { checkAnyUserData } from '../../../models/User';

const Intro = (props: {
    next: () => void;
}) => {

    const {userData} = useUserAuth();
    const hasAnyUserData: boolean = !!userData && checkAnyUserData(userData);

    useEffect(() => {
        if (!!userData && hasAnyUserData) {
            props.next();
        }
    }, [hasAnyUserData, userData, props]);

    if (!!userData && hasAnyUserData) {
        return null;
    }

    return (
        <StackWithButtons title="Welcome to S'wipe!" next={props.next}>
            <img src={logo} alt="S'wipe Kit" className="mb-md-3"/>
            <p>
                Start using this webapp by answering a few questions about yourself, the health of your gut, and
                general health conditions.
            </p>
            <p>
                Once you answered our questions, you will be able to register your S'wipe kit and start collecting
                samples!
            </p>
        </StackWithButtons>
    )
}

export default Intro;