import React, {useEffect, useMemo, useState} from "react";
import Column from '../../../components/Column';
import {useUserAuth} from "../../../contexts/UserAuthContext";
import {ColumnDef} from "@tanstack/react-table";
import TableWithPages from "../../../components/TableWithPages";
import {getUserKits} from "../../../firebase";
import {Badge, Nav, Navbar, Stack} from "react-bootstrap";
import Kit from "../../../models/Kit";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVial, faVials} from "@fortawesome/free-solid-svg-icons";
import {useLocation, useNavigate} from "react-router-dom";
import {dateToString} from "../../../utils/Utils";
import {Timestamp} from "firebase/firestore";


const TestKits = () => {

    const [userKits, setUserKits] = useState<Array<Kit>>([]);
    const {user} = useUserAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        getUserKits(user.uid)
            .then((kits) => setUserKits(kits));
    }, [user.uid, location])

    const columns = useMemo<ColumnDef<any>[]>(() => [
        {
            accessorKey: 'kitId',
            cell: info => info.getValue(),
            header: () => <span>Barcode</span>,
            footer: props => props.column.id
        },
        {
            accessorKey: 'numTests',
            header: 'Total'
        },
        {
            accessorFn: (test) => test.numTests - test.usedTests,
            cell: (props: {getValue: () => any}) => {
                const testsAvailable = props.getValue();
                return (
                    <Badge bg={testsAvailable > 0 ? 'success' : 'danger'}>{testsAvailable}</Badge>
                )
            },
            header: 'Available'
        },
        {
            accessorFn: (test) => test.numTests - test.usedTests,
            cell: (props: {getValue: () => any}) => {
                const testsAvailable = props.getValue();
                return (testsAvailable > 0 &&
                    <a href="/account/test">Collect sample</a>
                )
            },
            header: 'Testing'
        }
    ], [])

    return (
        <>
            <Column>
                <Stack className="gap-md-3 my-1 my-md-3" gap={1}>
                    <h3 className="align-self-center">Your test kits:</h3>
                    <TableWithPages data={userKits} columns={columns}/>
                    {/*initialSorting={{id: 'date', desc: true}}*/}
                </Stack>
            </Column>
            <Navbar fixed="bottom" className="mb-5 mb-md-6">
                <Nav className="justify-content-center" style={{width: "100%"}}>
                    <Button className="rounded-5 m-3" variant="primary"
                            onClick={() => navigate("/account/kits/add")}>
                        <FontAwesomeIcon icon={faVials} size="lg" className="me-3"/>Register S'wipe Kit
                    </Button>
                </Nav>
            </Navbar>
        </>
    )
}

export default TestKits;