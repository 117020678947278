import React, {useEffect, useMemo, useState} from "react";
import Column from '../../components/Column';
import {useUserAuth} from "../../contexts/UserAuthContext";
import {ColumnDef} from "@tanstack/react-table";
import TableWithPages from "../../components/TableWithPages";
import moment from "moment";
import {getUserReports} from "../../firebase";
import Report from "../../models/Report";
import {Stack} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faEye} from "@fortawesome/free-solid-svg-icons";


const Reports = () => {

    const [userReports, setUserReports] = useState<Array<Report>>([]);
    const {user} = useUserAuth();

    useEffect(() => {
        getUserReports(user.uid)
            .then((reports: Report[]) => setUserReports(reports));
    }, [user]);

    const columns = useMemo<ColumnDef<any>[]>(() => [
        {
            id: 'index',
            cell: ({row}) => row.index + 1
        },
        {
            accessorFn: ({date}) => moment(date.toDate()).format('YYYY-MM-DD'),
            header: 'Date'
        },
        {
            accessorKey: 'downloadURL',
            header: 'Download',
            cell: info => <a href={info.getValue() as string} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faDownload} size="lg" className="me-1"/>Download
            </a>
        },
        {
            accessorKey: 'reportId',
            header: 'View',
            cell: cellContext => <a href={`/account/report/${cellContext.getValue()}`}>
                <FontAwesomeIcon icon={faEye} size="lg" className="me-1"/>View
            </a>
        }
    ], [])

    return (
        <Column>
            <Stack className="gap-md-3 my-1 my-md-3" gap={1}>
                <h3 className="align-self-center">Your reports:</h3>
                <TableWithPages data={userReports} columns={columns} initialSorting={{id: 'Date', desc: true}}/>
            </Stack>
        </Column>
    )
}

export default Reports;