import React, {useEffect, useState} from "react";
import StackWithButtons from "../../../components/StackWithButtons";
import SingleOptionSelector from "../../../components/SingleOptionSelector";
import {useUserAuth} from "../../../contexts/UserAuthContext";
import {getAvailableTests} from "../../../firebase";
import {useNavigate, Link} from "react-router-dom";
import Test from "../../../models/Test";
import {Form} from "react-bootstrap";
import RoundedButton from "../../../components/RoundedButton";
import {dateToString} from "../../../utils/Utils";

const TestSelector = ({prev, next}: {
    prev: () => void,
    next: (test: Test) => void
}) => {

    const {user} = useUserAuth();
    const [showAll, setShowAll] = useState(false);
    const [labels, setLabels] = useState<{ [key: string]: Test }>({});
    const [selectedLabel, setSelectedLabel] = useState<string>();
    const navigate = useNavigate();

    useEffect(() => {
        getAvailableTests(user.uid, showAll)
            .then((tests) => {
                setLabels(tests.reduce((labels: { [key: string]: Test }, test: Test) => {
                    let label = `Test ${test.tubeNumber} from kit ${test.kitId}`;
                    if (!!test.date) {
                        label += `\n(used on ${dateToString(test.date)})`
                    }
                    labels[label] = test;
                    return labels;
                }, {}));
            });
    }, [user.uid, showAll, navigate]);

    function handleNext() {
        if (!selectedLabel) return;

        const test = labels[selectedLabel];
        if (!test) return;

        return next(test);
    }

    return (
        <StackWithButtons title="Select Test" prev={prev} next={handleNext} isNextAvailable={!!selectedLabel}
                          description="Select the test number displayed on the bag with the wipe that you just used">
            <p className="d-flex">
                <Form.Check.Label className="ms-auto">
                    <Form.Check.Input type="checkbox" name="showAll"
                                      checked={showAll} onChange={() => setShowAll(!showAll)}/>
                    <span className="m-1">Show all tests</span>
                </Form.Check.Label>
            </p>
            <SingleOptionSelector id="label-selector" options={Object.keys(labels)} value={selectedLabel}
                                  setValue={setSelectedLabel}/>
            {Object.keys(labels).length === 0 &&
                <>
                    <p className="text-start">
                        No tests available. Please, purchase S'Wipe testing kit
                        at <a href="https://s-wipe.shop" target="_blank" rel="noreferrer">https://s-wipe.shop</a> and
                        add it to your account
                    </p>
                    <Link to="/account/kits/add">
                        <RoundedButton>Add S'Wipe Kit</RoundedButton>
                    </Link>
                </>
            }
        </StackWithButtons>
    )
}

export default TestSelector;