class User {
    email: string;
    firstName: string;
    lastName: string;
    sex: string | undefined;
    gender: string | undefined;
    race: Array<string> | undefined;
    dateOfBirth: string | undefined;
    weight: number | undefined;
    weightUnit: string | undefined;
    height: number | undefined;
    heightUnit: string | undefined;
    diets: Array<string> | undefined;
    // gutConditions: string[] | undefined;
    // gutSymptoms: string[] | undefined;
    // orderId: string | undefined;
    // createdBy: string | undefined;

    constructor(fields: {email: string, firstName: string, lastName: string}) {
        this.email = fields.email;
        this.firstName = fields.firstName;
        this.lastName = fields.lastName;
        Object.assign(this, fields);
    }

    checkAllFields() {
        for (const key in this) {
            // Skip the fields that can be undefined
            // if (key in ['gutIssues', 'orderId', 'createdBy']) continue;
            if (typeof this[key] === "undefined") {
                return false;
            }
        }
        return true;
    }

    checkAnyFields() {
        for (const key in this) {
            if (['email', 'firstName', 'lastName'].includes(key)) {
                continue;
            }

            if (typeof this[key] !== "undefined") {
                return true;
            }
        }
        return false;
    }

    checkNameFields() {
        for (const key of ['firstName', 'lastName']) {
            if (typeof this[key] === "undefined") {
                return false;
            }
        }
        return true;
    }
}


export function checkAllUserData(userData: {email: string, firstName: string, lastName: string}) {
    const userModel = new User(userData);
    return userModel.checkAllFields();
    // return ['gender', 'race', 'dateOfBirth', 'weight', 'weightUnit', 'height', 'heightUnit', 'diets']
    //     .every(key => userData.hasOwnProperty(key));
}


export function checkAnyUserData(userData: {email: string, firstName: string, lastName: string}) {
    const userModel = new User(userData);
    return userModel.checkAnyFields();
}


export function checkNameFields(userData: {email: string, firstName: string, lastName: string}) {
    const userModel = new User(userData);
    return userModel.checkNameFields();
}


export default User;