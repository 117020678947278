import React from 'react'
import 'react-multi-carousel/lib/styles.css';
import {useUserAuth} from "../../../contexts/UserAuthContext";
import {useState} from "react";
import {
    faBurger,
    faDrumstickBite,
    faFish,
    faPlantWilt,
    faScaleBalanced,
    faUtensils,
    faV,
    faWheatAwn
} from "@fortawesome/free-solid-svg-icons";
import StackWithButtons from "../../../components/StackWithButtons";
import MultiOptionSelector, {MultiOption} from "../../../components/MultiOptionSelector";

const Diet = (props: {
    prev: () => void;
    next: () => void;
}) => {

    const {updateUserData, userData} = useUserAuth();
    const diets = [
        new MultiOption("Balanced Diet", faScaleBalanced, "black",
            "A balanced diet consists of a variety of foods that provide essential nutrients, including fruits, " +
            "vegetables, whole grains, lean proteins, and healthy fats, while limiting added sugars, salt, and " +
            "unhealthy fats."),
        new MultiOption("Carnivore", faDrumstickBite, "red",
            "The carnivore diet consists primarily of animal products, including meat, fish, eggs, and some dairy, " +
            "while excluding all plant-based foods like fruits, vegetables, grains, and legumes."),
        new MultiOption("Gluten Free", faWheatAwn, "brown",
            "A gluten-free diet excludes all foods containing gluten, a protein found in wheat, barley, rye, and " +
            "their derivatives, focusing instead on naturally gluten-free foods such as fruits, vegetables, meat, " +
            "fish, eggs, nuts, and gluten-free grains like rice and quinoa."),
        new MultiOption("Low Carbs", faUtensils, "blue",
            "A low-carb diet limits carbohydrate intake, focusing instead on consuming protein, healthy fats, and " +
            "non-starchy vegetables while reducing or avoiding foods like bread, pasta, rice, sugary snacks, and " +
            "some fruits to promote weight loss and improved metabolic health."),
        new MultiOption("Vegetarian", faV, "green",
            "A vegetarian diet focuses on consuming plant-based foods such as fruits, vegetables, grains, nuts, " +
            "seeds, and legumes, while excluding meat, fish, and poultry; it may include dairy products and eggs, " +
            "depending on the type of vegetarianism.\n"),
        new MultiOption("Pescatarian", faFish, "blue",
            "A pescatarian diet primarily consists of plant-based foods, such as fruits, vegetables, grains, nuts, " +
            "and legumes, while including fish and other seafood as the main source of animal protein; it typically " +
            "excludes meat and poultry, but may include dairy and eggs depending on individual preferences."),
        new MultiOption("Standard American Diet", faBurger, "red",
            "The standard American diet is often characterized by high consumption of processed and convenience foods, " +
            "sugary beverages, red meats, and refined grains, with relatively low intake of fruits, vegetables, whole " +
            "grains, and healthy fats."),
        new MultiOption("Vegan", faPlantWilt, "green",
            "A vegan diet excludes all animal products, including meat, fish, dairy, eggs, and honey, focusing " +
            "instead on plant-based foods such as fruits, vegetables, grains, legumes, nuts, seeds, and plant-based " +
            "alternatives to traditional animal products.")
    ];
    const [selectedDiets, setSelectedDiets] = useState(userData.diets || []);
    // const dietButtons = Object.keys(diets).map((diet) =>
    //     <ToggleButton id={diet.replace(' ', '-').toLowerCase()}
    //                   key={diet.replace(' ', '-').toLowerCase()}
    //                   value={diet} type="checkbox" className="d-flex align-items-center rounded-5"
    //                   variant="outline-dark"
    //                   checked={selectedDiets !== undefined && selectedDiets.indexOf(diet) > -1}
    //                   onChange={(e) => {
    //                       const selected = selectedDiets !== undefined ? selectedDiets.slice() : [];
    //                       const index = selected.indexOf(diet);
    //                       if (index > -1) {
    //                           selected.splice(index, 1);
    //                       } else {
    //                           selected.push(diet);
    //                       }
    //                       setSelectedDiets(selected);
    //                   }}>
    //         <FontAwesomeIcon icon={diets[diet][0]}
    //                          color={selectedDiets.indexOf(diet) > -1 ? "white" : diets[diet][1]}/>&nbsp;<span
    //         className="flex-grow-1">{diet}</span>
    //     </ToggleButton>
    // )

    function handleClick() {
        updateUserData("diets", selectedDiets)
            .then((success: boolean) => {
                if (success) {
                    props.next();
                }
            });
    }

    return (
        <StackWithButtons title="What are your eating habits?"
                          description="Please select all diet options that apply"
                          prev={props.prev}
                          next={handleClick}
                          isNextAvailable={selectedDiets?.length > 0}>
            <MultiOptionSelector options={diets} selectedOptions={selectedDiets} setSelectedOptions={setSelectedDiets}/>
            {/*// <>*/}
            {/*//     <Stack className="overflow-y-auto" gap={1} style={{height: '100%'}}>*/}
            {/*//         <h3 className="flex-grow-1">What are your eating habits?</h3>*/}
            {/*//         <p>Please select all diet options that apply.</p>*/}
            {/*//         {dietButtons}*/}
            {/*//         <span className="flex-grow-1"/>*/}
            {/*//     </Stack>*/}
            {/*//     <Stack direction="horizontal" gap={3}>*/}
            {/*//         <Button className="w-50" variant="secondary" onClick={props.prev}>Previous</Button>*/}
            {/*//         <Button className="w-50" onClick={handleClick} disabled={selectedDiets.length === 0}>Next</Button>*/}
            {/*//     </Stack>*/}
            {/*// </>*/}
        </StackWithButtons>
    )
}

export default Diet;