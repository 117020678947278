import React, {useState} from 'react';
import StackWithButtons from "../../../components/StackWithButtons";
import MultiOptionSelector, {MultiOption} from "../../../components/MultiOptionSelector";
import {useUserAuth} from "../../../contexts/UserAuthContext";

const GutSymptoms = ({prev, next}: {
    prev: () => void,
    next: () => void,
}) => {

    const {updateUserData, userData} = useUserAuth();
    const gutSymptoms = [
        new MultiOption("Diarrhea", undefined, undefined, "A condition in which feces are discharged from the bowels frequently and in a liquid form."),
        new MultiOption("Heartburn", undefined, undefined, "A form of indigestion felt as a burning sensation in the chest, caused by acid regurgitation into the esophagus."),
        new MultiOption("Constipation", undefined, undefined, "A condition in which there is difficulty in emptying the bowels, usually associated with hardened feces."),
        new MultiOption("Acid Reflux", undefined, undefined, "A condition in which acidic gastric fluid is regurgitated into the esophagus, causing heartburn."),
        new MultiOption("Stomach Pain", undefined, undefined, "Pain that occurs between the chest and pelvic regions."),
        new MultiOption("Bloating", undefined, undefined, "A condition where your belly feels full and tight, often due to gas."),
        new MultiOption("Nausea", undefined, undefined, "A feeling of sickness with an inclination to vomit."),
        new MultiOption("Indigestion", undefined, undefined, "Pain or discomfort in the stomach associated with difficulty in digesting food.")
    ];
    const [selectedSymptoms, setSelectedSymptoms] = useState<Array<string>>(userData.gutSymptoms ?? []);

    function handleNext() {
        updateUserData('gutSymptoms', selectedSymptoms)
        .then((result: boolean) => {
            if (result) {
                next();
            }
        });
    }

    return (
        <StackWithButtons title="Gut Symptoms"
                          description="Do you regularly experience any of these symptoms:"
                          prev={prev} next={handleNext}>
            <MultiOptionSelector options={gutSymptoms} selectedOptions={selectedSymptoms}
                                 setSelectedOptions={setSelectedSymptoms} hasOtherOption={true} hasNoneOption={true}/>
        </StackWithButtons>
    )
}

export default GutSymptoms;