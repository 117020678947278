import React, {useState} from 'react';
import {FloatingLabel, Form, InputGroup} from 'react-bootstrap';
import 'react-multi-carousel/lib/styles.css';
import {useUserAuth} from "../../../contexts/UserAuthContext";
import StackWithButtons from "../../../components/StackWithButtons";

const Biometrics = (props: {
    prev: () => void;
    next: () => void;
}) => {

    const Weight = {Lbs: 'lbs', Kg: 'kg'}
    const Height = {FtIn: 'ft in', Cm: 'cm'}

    const {updateUserData, userData} = useUserAuth();
    const today = new Date();
    const [weightUnit, setWeightUnit] = useState(userData.weightUnit || Weight.Lbs);
    const [heightUnit, setHeightUnit] = useState(userData.heightUnit || Height.FtIn);
    const [dateOfBirth, setDateOfBirth] = useState<number>(userData.dateOfBirth || '');
    const [weight, setWeight] = useState<number>(userData.weight || '');
    const [height, setHeight] = useState<number>(
        (userData.height && heightUnit === Height.FtIn) ? Math.floor(userData.height) : (userData.height || ''));
    const [heightInches, setHeightInches] = useState<number>(
        (userData.height && heightUnit === Height.FtIn) ? Math.round(12 * (userData.height - Math.floor(userData.height))) : null
    );

    function handleClick() {
        updateUserData("dateOfBirth", dateOfBirth, "weight", weight, "weightUnit", weightUnit,
            "height", heightUnit === Height.Cm ? height : height + heightInches / 12, "heightUnit", heightUnit)
            .then((success: boolean) => {
                if (success) {
                    props.next();
                }
            })
    }

    return (
        <StackWithButtons title="What are your Biometrics?"
                          description="Please select your date of birth, weight and height for better personalized testing experience"
                          prev={props.prev}
                          next={handleClick}
                          isNextAvailable={!!dateOfBirth && !!weight && !!weightUnit && !!height && !!heightUnit}>

            <FloatingLabel label="Year of Birth (YYYY)">
                <Form.Control type="number" min={today.getFullYear() - 100} max={today.getFullYear()} step={1}
                              value={dateOfBirth} placeholder="Year of Birth (YYYY)"
                              onChange={e => setDateOfBirth(parseInt(e.currentTarget.value))}/>
            </FloatingLabel>
            <InputGroup>
                <FloatingLabel label={"Weight (" + weightUnit + ")"}>
                    <Form.Control type="number" id="weight" name="weight" value={weight}
                                  onChange={(e) => setWeight(parseFloat(e.currentTarget.value))}/>
                </FloatingLabel>
                <InputGroup.Text style={{cursor: 'pointer'}} className="user-select-none"
                                 onClick={() => {
                                     setWeightUnit(weightUnit === Weight.Lbs ? Weight.Kg : Weight.Lbs)
                                 }}>
                    {weightUnit}
                </InputGroup.Text>
            </InputGroup>
            <InputGroup>
                <FloatingLabel label={"Height (" + (heightUnit === Height.FtIn ? "ft" : heightUnit) + ")"}>
                    <Form.Control type="number" id="height" value={height} onChange={
                        (e) => setHeight(parseInt(e.currentTarget.value))
                    }/>
                </FloatingLabel>
                {heightUnit === Height.FtIn &&
                    <FloatingLabel label="(in)">
                        <Form.Control type="number" id="height" value={heightInches ?? ''} min="1" max="11"
                                      onChange={
                                          (e) => setHeightInches(
                                              parseInt(e.currentTarget.value))
                                      }/>
                    </FloatingLabel>
                }
                <InputGroup.Text style={{cursor: 'pointer'}} className="user-select-none"
                                 onClick={() =>
                                     setHeightUnit(heightUnit === Height.FtIn ? Height.Cm : Height.FtIn)
                                 }>
                    {heightUnit}
                </InputGroup.Text>
            </InputGroup>
        </StackWithButtons>
    )
}

export default Biometrics;